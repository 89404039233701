import { defineComponent, reactive } from 'vue';
import { TermsService } from '@hems/service';
import { TermsHelper } from '@hems/util';
import { TERMS_TYPE } from '@hems/util/src/constant';
import { InstallerJoinPopup, GeneralJoinPopup } from '@/components';
export default defineComponent({
    name: 'JoinPopupContainer',
    components: {
        InstallerJoinPopup,
        GeneralJoinPopup,
    },
    emits: ['close'],
    props: {
        joinType: {
            type: String,
            default: 'general',
        },
        social: Object,
        locale: String,
    },
    async setup(props, { emit }) {
        const termsService = new TermsService(window.axiosInstance.axios);
        const termsType = props.joinType === 'general' ? TERMS_TYPE.USER : TERMS_TYPE.INSTALLER;
        const state = reactive({
            termsList: [],
        });
        const getTermsList = async () => {
            try {
                const terms = await termsService.getTerms(termsType);
                state.termsList = TermsHelper.getSortedTermsByEffectiveDate(terms);
            }
            catch (e) {
                console.error(e);
            }
        };
        await getTermsList();
        return {
            state,
        };
    },
});
